import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { AuthService } from "../auth/auth.service";

interface ICreateRole {
  name: string;
  for_admin?: number;
  permissions: number[];
  company_id?: number;
}

interface IUpdateRole {
  name: string;
  for_admin?: number;
  permissions: number[];
  company_id?: number;
  role_id: number;
}

@Injectable({
  providedIn: "root",
})
export class RolesService {
  constructor(private http: HttpClientService) {}

  public createRole(body: ICreateRole) {
    return this.http.post(body, "user/role/create");
  }

  public getRoles(isPaginate: boolean = false, page?: number, search?: string) {
    return this.http.get("user/role/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getRole(roleId: number) {
    return this.http.get("user/role/get", { role_id: roleId });
  }

  public updateRole(body: IUpdateRole) {
    return this.http.post(body, "user/role/update");
  }

  public deleteRole(roleId: number) {
    return this.http.post({ role_id: roleId }, "user/role/delete");
  }

  public validatePermissions(permissions: number[], status: boolean) {
    return this.http.post(
      { permissions, status: +status },
      "user/role/validate-permissions"
    );
  }

  public listPermissions() {
    return this.http.get("user/role/list-permissions");
  }

  public listPages() {
    return this.http.get("user/role/list-pages");
  }
}

declare var gapi: any | {};
declare var google: any | {};

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleCalendarService {
  ////////////********************* */
  // Discovery doc URL for APIs used by the quickstart
  DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  scopes: string[] = [
    "https://www.googleapis.com/auth/calendar",
    "https://www.googleapis.com/auth/calendar.readonly",
    "https://www.googleapis.com/auth/calendar.events",
    "https://www.googleapis.com/auth/calendar.events.readonly",
    "https://www.googleapis.com/auth/calendar.settings.readonly"
  ];

  tokenClient;
  gapiInited: boolean = false;
  gisInited = false;
  apiConfig = {
    clientId: environment.GAPI_CLIENT_ID,
    clientSecret: environment.GAPI_CLIENT_SECRET,
    apiKey: environment.GAPI_API_KEY,
    scope: this.scopes.join(" "),
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };
  ////////////********************* */

  isAuthorized = false;


  eventsRefreshedSource = new Subject();
  signinCompleteSource = new Subject<any>();

  // this is the guy to subscribe to.
  eventsRefreshed$ = this.eventsRefreshedSource.asObservable();
  signinComplete$ = this.signinCompleteSource.asObservable();

  queryOptions = {
    timeMin: moment().toISOString(),
    timeMax: null,
  };

  constructor() {
    this.gapiLoaded();
    this.gisLoaded();
  }

  /**
   * Callback after api.js is loaded.
   */
  gapiLoaded() {
    gapi.load("client", this.initializeGapiClient);
  }

  /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
   */
  async initializeGapiClient() {
    await gapi.client.init({
      apiKey: this.apiConfig.apiKey,
      discoveryDocs: this.apiConfig.discoveryDocs,
    });
    // this.gapiInited = true;
    // this.maybeEnableButtons();
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.apiConfig.clientId,
      scope: this.apiConfig.scope,
      callback: "", // defined later
    });
    this.gisInited = true;
    this.maybeEnableButtons();
  }

  /**
   * Enables user interaction after all libraries are loaded.
   */
  maybeEnableButtons() {
    if (this.gapiInited && this.gisInited) {
      console.log("enable buttons");

      // document.getElementById('authorize_button').style.visibility = 'visible';
    }
  }
  /**
   *  Sign in the user upon button click.
   */
  handleAuthClick() {
    this.tokenClient.callback = async (resp) => {
      console.log(resp);

      if (resp.error !== undefined) {
        throw resp;
      }
      // document.getElementById('signout_button').style.visibility = 'visible';
      // document.getElementById('authorize_button').innerText = 'Refresh';
      await this.listUpcomingEvents();
    };

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: "" });
    }
  }

  /**
   *  Sign out the user upon button click.
   */
  handleSignoutClick() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
      // document.getElementById('content').innerText = '';
      // document.getElementById('authorize_button').innerText = 'Authorize';
      // document.getElementById('signout_button').style.visibility = 'hidden';
    }
  }

  /**
   * Print the summary and start datetime/date of the next ten events in
   * the authorized user's calendar. If no events are found an
   * appropriate message is printed.
   */
  async listUpcomingEvents() {
    let response;
    try {
      const request = {
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      };
      response = await gapi.client.calendar.events.list(request);
    } catch (err) {
      console.log(err);
      
      // document.getElementById('content').innerText = err.message;
      return;
    }

    const events = response.result.items;
    if (!events || events.length == 0) {
      // document.getElementById('content').innerText = 'No events found.';
      return;
    }
    // Flatten to string to display
    const output = events.reduce(
      (str, event) =>
        `${str}${event.summary} (${
          event.start.dateTime || event.start.date
        })\n`,
      "Events:\n"
    );
    // document.getElementById('content').innerText = output;
  }

  // initGoogleOAuth(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     gapi.load(
  //       "auth2",
  //       async () => {
  //         const gAuth = await gapi.auth2.init({
  //           client_id: this.apiConfig.clientId,
  //           fetch_basic_profile: true,
  //           scope: "profile email",
  //         });
  //         resolve(gAuth);
  //       },
  //       reject
  //     );
  //   });
  // }

  // private updateSigninStatus = (isSignedIn: boolean) => {
  //   this.isAuthorized = isSignedIn;
  // };
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class AreaService {
  constructor(private http: HttpClientService) {}

  public createArea(body: { name_en: number; name_ar: number }) {
    return this.http.postFormData(body, "area/create");
  }

  public getAllAreas(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("area/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getArea(areaId: number, isLocalization: boolean = false) {
    return this.http.get("area/get", {
      area_id: areaId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateArea(body: { area_id: number; name_en: string }) {
    return this.http.postFormData(body, "area/update");
  }

  public deleteArea(areaId: number) {
    return this.http.post({ area_id: areaId }, "area/delete");
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FacadeService } from "../facade.service";
import { MessagingService } from "../notification/notification.service";
declare var $;

interface NotiticationData {
  id: string;
  key: string;
  msg: string;
}

@Injectable({
  providedIn: "root",
})
export class DisplayNotificationsService {
  // Notification Properties
  public unreadNotifications = [];
  public unreadNotificationsSubject = new BehaviorSubject(0);
  public currentNotificationPage: number = 1;
  public lastNotificationPage: number = 2;
  private notifications = [];
  public notificationsSubject = new BehaviorSubject(this.notifications);
  public seeMore: boolean = true;

  constructor(
    private facade: FacadeService,
    private messagingService: MessagingService
  ) {
    this.messagingService.currentMessage.subscribe((msg) => {
      if (msg) {
        // Notifications on start
        this.showNotification("bottom", "left", msg.data);
      }
      this.currentNotificationPage = 1;
      this.getAllNotifications();
      this.getTotalUnread();
    });
  }

  // Notification Methods
  private getTotalUnread() {
    this.facade.notificationService.totalUnread.subscribe((res) => {
      this.unreadNotifications = res.data;
      this.unreadNotificationsSubject.next(this.unreadNotifications.length);
    });
  }

  private getAllNotifications() {
    this.facade.notificationService
      .getAllNotificationsPaginate(this.currentNotificationPage)
      .subscribe((res) => {
        if (this.currentNotificationPage > 1) {
          this.notifications.push(...res.data.data);
        } else {
          this.notifications = res.data.data;
        }
        this.currentNotificationPage = res.data.current_page;
        this.lastNotificationPage = res.data.last_page;
        this.notificationsSubject.next(this.notifications);
        this.checkForMore();
      });
  }

  public loadMoreNotifications() {
    if (this.currentNotificationPage < this.lastNotificationPage) {
      this.currentNotificationPage++;
      this.getAllNotifications();
    }
  }

  private checkForMore() {
    if (this.currentNotificationPage === this.lastNotificationPage) {
      this.seeMore = false;
    }
  }

  public markAllNotificationsAsRead() {
    if (this.unreadNotifications.length > 0) {
      this.facade.notificationService.markAsRead.subscribe(() => {
        this.unreadNotifications.length = 0;
        this.unreadNotificationsSubject.next(this.unreadNotifications.length);
      });
    }
  }

  public markNotificationAsRead(notificationId: string) {
    let isRead = this.notifications.find((notif) => notif.id === notificationId)
      .read_at;
    if (!isRead) {
      this.facade.notificationService
        .getNotification(notificationId)
        .subscribe(() => {
          // Decrease notification by 1 for each read notification
          this.unreadNotifications.pop();
          const unreadNumber = this.unreadNotifications.length;
          this.unreadNotificationsSubject.next(unreadNumber);

          // Update notification to be marked as read
          let updateIndex = this.notifications
            .map((item) => item.id)
            .indexOf(notificationId);
          this.notifications[updateIndex].read_at = 1;
        });
    }
  }

  showNotification(from = "bottom", align = "left", data: NotiticationData) {
    const type = ["", "info", "success", "warning", "danger"];

    const color = Math.floor(Math.random() * 4 + 1);

    $.notify(
      {
        icon: "notifications",
        message: data.msg,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a routerLink="/orders/edit/"' +
          data.id +
          'target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
interface ILead {
  name: string;
  company_id?: number;
  title: string;
  phones: [
    {
      number: number;
      country_code: number;
    }
  ];
  sources: number[];
  email?: string;
  notes?: string;
}

interface IUpdateLead {
  lead_id: number;
  name: string;
  company_id?: number;
  title: string;
  phones: [
    {
      number: number;
      country_code: number;
    }
  ];
  sources: number[];
  email?: string;
  notes?: string;
}
const tiktokLoginLink = "https://ads.tiktok.com/marketing_api/auth?app_id=7146164643356999681&state=your_custom_params&redirect_uri=https://leadscrm.net/leads/tiktok&rid=c96pyz1b73w";
@Injectable({
  providedIn: "root",
})
export class LeadsService {
  constructor(private http: HttpClientService, private httpClient: HttpClient) { }
  host = environment.host;
  localHost = environment.BasemHost;
  public creatLead(body: ILead) {
    return this.http.post(body, "lead/create");
  }

  public getLead(leadId: number) {
    return this.http.get("lead/get", { lead_id: leadId });
  }

  public getFacebookPages(page: number = 1) {
    return this.http.get("lead/facebook/get-facebook-pages", { page });
  }

  getfbPagesFromDataBase(
    isPaginate: boolean = false,
    page: number = 1,
    search?: string
  ) {
    return this.http.get("lead/facebook/get-fb-pages", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }
  public addFacebookLeads(body) {
    return this.http.post(body, "lead/facebook/store-page-data");
  }
  public getPageForms(
    pageId: number,
    isPaginate: boolean = false,
    page: number = 1,
    search?: string
  ) {
    return this.http.get("lead/facebook/get-page-forms", {
      page_id: pageId,
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }
  public switchLeadFormRedirect(formId, isRedirect) {
    return this.http.put({ redirect: isRedirect }, `forms/${formId}`);
  }

  public deleteUserFromForm(formId: number, userId: number) {
    return this.http.post(
      { form_id: formId, user_id: userId },
      "lead/facebook/delete-from-form"
    );
  }

  public getAllLeads(
    isPaginate: boolean = false,
    page: number = 1,
    cold_calls: boolean = false,
    search?: string,
    is_admin: boolean = true,
    from?: string,
    to?: string
  ) {

    console.log(cold_calls)
    return this.http.get("lead/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      cold_calls: `${+cold_calls}`,
      search,
      is_admin,
      from,
      to,
    });
  }

  public getAllLeadsByStatus(
    isPaginate: boolean = false,
    page: number = 1,
    status_id?: number
  ) {
    return this.http.get("lead/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      cold_calls: 0,
      status_id,
    });
  }

  public getFilteredLeads(
    isPaginate: boolean = false,
    page: number = 1,
    cold_calls: boolean = false,
    AgentId?: number,
    status_id?: string,
    search?: string,
    from?: string,
    to?: string,
    // is_admin: boolean = true,
    sort_by?: string,
    unassigned?: boolean,
    soft_deleted?: boolean
  ) {
    return this.http.get("lead/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      AgentId,
      status_id,
      search,
      from,
      cold_calls: `${+cold_calls}`,
      to,
      // is_admin,
      sort_by,
      unassigned,
      soft_deleted
    });
  }

  public unassignLead(leadID: number) {
    const url = `lead/unassigned?lead_id=${leadID}`;
    return this.http.get(url, null);
  }

  public newLead(leadIDs: number[]) {
    const idsString = leadIDs.join(',');
    const url = `lead/new-lead?lead_ids=${idsString}`;
    // console.log("idsString: " , idsString, "URL: ", url);
    return this.http.get(url);
  }

  /////////////////////////
  // public getAllCampigns(
  //   accessToken: string,
  //   appId: string
  // ) {
  //   return this.http.get("myCampigns", {
  //     access_token: accessToken,
  //     app_id: appId
  //   });
  // }
  ////////////////////////

  public updateLead(body: IUpdateLead) {
    return this.http.post(body, "lead/update");
  }

  public assignLeadsToUsers(body: {
    lead_id: number;
    users: number[];
    company_id: number;
  }) {
    return this.http.post(body, "lead/assign-lead-to-users");
  }
  public uploadfile(file: File) {
    return this.http.postFormData(file, "lead/import-bulk");
  }

  public deleteLead(leadId: number) {
    return this.http.post({ lead_id: leadId }, "lead/delete");
  }

  public restoreLead(leadId: number) {
    return this.http.post({ lead_id: leadId }, "lead/restore");
  }

  public addfromcontact(body: {
    title: string;
    name: string;
    notes: string;
    phones: number;
    number: number;
    country_code: number;
    email: string;
    company_id: number;
  }) {
    return this.http.post(body, "lead/add-from-contacts");
  }

  public storeLeadsFromForm(formId: number, platform?: string) {
    let platformEndpoint;
    if (platform == "tiktok") {
      platformEndpoint = `tiktok/forms/${formId}/get-leads`;
      // return this.http.get( `forms/${formId}`);
    } else {
      platformEndpoint = `lead/facebook/store-leads-from-form`;
    }
    // return this.http.post({}, platformEndpoint);
    return this.http.get(platformEndpoint, {
      form_id: formId,
    });
  }

  public addAgentToForm(body: {
    form_id: number;
    user_id: number;
    percentage: string;
  }) {
    return this.http.post(body, "lead/facebook/add-to-forms");
  }
  public filterLeads(body) {
    return this.http.get("lead/filter", body);
  }
  public filterbydate(body) {
    return this.http.get("lead/filter-by-date", body);
  }
  public getInitStatuses() {
    return this.http.get("lead-statuses");
  }
  public addUpdateLeadStatus(body: {
    lead_id: number;
    status_id: number;
    comment?: string;
  }) {
    return this.http.post(body, "lead/status");
  }
  getAllStatisticsStatuses(body: {

    cold_calls: any,
    agent_id?: number;
    from?: string;
    to?: string;
  }) {
    return this.http.get("lead-statuses/stats", body);
  }

  setReminderToLead(body: { lead_id: number; day: string; time: string }) {
    return this.http.post(body, "lead/reminder");
  }

  getAllMessages() {
    return this.http.get("contacts");
  }
  getUserMessages(contactId) {
    return this.http.get(`contacts/${contactId}/messages`);
  }
  sendMessage(message, contactId) {
    return this.http.post(
      { message: message },
      `contacts/${contactId}/send-message`
    );
  }

  sendWhatsappMessage(leadId: number) {
    return this.http.post({}, `leads/${leadId}/notify-agent`);
  }

  // tiktok
  get getTiktokLoginLink() {
    return tiktokLoginLink;
  }
  sendTiktokToken(token) {
    return this.http.post({ auth_code: token }, `tiktok/auth`);
  }

  getTiktokLibraries(
    isPaginate: boolean = false,
    page: number = 1,
    search?: string
  ) {
    return this.http.get(`tiktok/get-tiktok-pages`, {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }
  public getTiktokPageForms(
    pageId: number,
    isPaginate: boolean = false,
    page: number = 1,
    search?: string
  ) {
    return this.http.get("tiktok/get-page-forms", {
      page_id: pageId,
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public addAgentToTiktokForm(body: {
    form_id: number;
    user_id: number;
    percentage: string;
  }) {
    return this.http.post(body, "tiktok/add-to-forms");
  }

  public switchTiktokLeadFormRedirect(formId, isRedirect) {
    return this.http.put({ redirect: isRedirect }, `tiktok/forms/${formId}`);
  }
  // tiktok

  /// setMessageToForm
  public setMessage(body: {
    form_id: number;
    active: number;
    type: string;
    message: string;
  }) {
    return this.http.post(body, "forms/sms");
  }

  uploadLeadFile(file: File) {
    console.log("dddddd")
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post(this.host + '/api/lead/import-bulk', formData)
  }
  downloadExcelFile(
    isPaginate: boolean = false,
    page: number = 1,
    cold_calls: boolean = false,
    AgentId?: number,
    status_id?: string,
    search?: string,
    from?: string,
    to?: string,
    // is_admin: boolean = true,
    sort_by?: string,
    unassigned?: boolean,
    soft_deleted?: boolean
  ) {
    return this.httpClient.post(this.host + '/api/lead/export_template', {
      is_paginate: `${+isPaginate}`,
      page,
      AgentId,
      status_id,
      search,
      from,
      cold_calls: `${+cold_calls}`,
      to,
      // is_admin,
      sort_by,
      unassigned,
      soft_deleted
    });
  }

  getColdLeads(page = 1) {

    return this.httpClient.get(this.host + '/api/lead/get-all', {
      params: {
        'cold_calls': 1 + '',
        'page': page + ''
      }
    }
    )
  }

  getColdLeadsPerStatus(page = 1, statusId: number) {
    return this.httpClient.get(this.host + '/api/lead/get-all', {
      params: {
        'cold_calls': 1 + '',
        'page': page + '',
        'status_id': statusId + ''
      }
    }
    )
  }

  getLeadsReminders(date: string, page: number = 1) {
    return this.httpClient.get(this.host + '/api/lead/getReminders', {
      params: {
        'date': date,
        'page': page + ''
      }
    })
  }

  getColdLeadsStatus() {
    return this.httpClient.get(this.host + '/api/lead-statuses/stats', {
      params: {
        'cold_calls': 1 + ''
      }
    })
  }

  getAllReminderStates() {
    return this.httpClient.get(this.host + '/api/lead/reminder_stats')
  }
  assignUserToLeads(id: number, leads) {
    let params = {};
    leads.forEach((ele, index) => {
      params['leads[' + index + "]"] = ele;

    })
    params['user_id'] = id;


    return this.httpClient.post(this.host + '/api/lead/assignUserToLeads', null, { params: params })
  }



}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { BehaviorSubject, Observable } from "rxjs";
import { io } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  socket;
  public message$: BehaviorSubject<string> = new BehaviorSubject("");
  public deletedMessage$: BehaviorSubject<string> = new BehaviorSubject("");
  public contactLastMsg$: BehaviorSubject<string> = new BehaviorSubject("");
  constructor(private http: HttpClientService) {
    this.socket = io("https://vinci.leadscrm.net:5000");
    // this.socket = io("http://192.168.1.52:6001");
  }

  public getNewMessage = (contactId) => {
    // console.log(`Contact.${contactId}:App\\Events\\MessageReceived`);
    // this.socket.removeAllListeners(`Contact.${contactId}:App\\Events\\MessageReceived`);
    // let socket = this.socket.connect();
    // if(!socket.connected){
      if(this.socket){
        this.socket.off();
      }
    this.socket.on(
      `Contact.${contactId}:App\\Events\\MessageReceived`,
      (message) => {        
        // console.log(message);
        this.message$.next(message.message);
      }
    );
    // }
    return this.message$.asObservable();
  };

  public getDeletedMessage = (contactId) => {
    // console.log(`Contact.${contactId}:App\\Events\\MessageReceived`);
    // this.socket.removeAllListeners(`Contact.${contactId}:App\\Events\\MessageReceived`);
    // let socket = this.socket.connect();
    // if(!socket.connected){
    // this.socket.off();
    this.socket.on(
      `Contact.${contactId}:App\\Events\\MessageDeleted`,
      (message) => {
        // console.log(message);
        this.deletedMessage$.next(message.message);
      }
    );
    // }
    return this.deletedMessage$.asObservable();
  };

  public getContactsLastMessage = (companyId) => {
    // console.log("connected");

    this.socket.off();
    this.socket.on(`List.${companyId}:App\\Events\\MessageReceived`, (msg) => {
      // console.log(msg);
      this.contactLastMsg$.next(msg.contact);
    });
    return this.contactLastMsg$.asObservable();
  };

  disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
    // console.log("disconnected");
  }
  // public disconnectUserEvent() {
  //   return this.socket.disconnect();
  // }

  // public checkSocketConnection() {
  //   let socket = this.socket.connect();
  //   console.log('check 1', socket.connected);
  //   socket.on('connect', function () {
  //     console.log('check 2', socket.connected);
  //   });
  // }

  // sendFacebookPages
  public addFacebookPages(body) {
    return this.http.post(body, "pages");
  }
  // getSavedPages
  public getFacebookPages(page: number = 1, is_paginate?: true) {
    return this.http.get("pages", {
      page: page,
      is_paginate: is_paginate,
    });
  }

  // get page contacts
  getAllMessages(page: number = 1, is_paginate?: true) {
    return this.http.get("contacts", {
      page: page,
      is_paginate: is_paginate,
    });
  }

  // get contact messages
  getUserMessages(contactId, page: number = 1, is_paginate?: true) {
    return this.http.get(`contacts/${contactId}/messages`, {
      page: page,
      is_paginate: is_paginate,
    });
  }
  // send message to contact
  sendMessage(message, contactId) {
    return this.http.post(
      { message: message },
      `contacts/${contactId}/send-message`
    );
  }

  sendWhatsappNumbers(body){
    return this.http.post(
      body,'whatsapp-numbers'
    )
  }
}

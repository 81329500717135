import { Injectable } from '@angular/core';
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface CreateEmployee {
  name:string,
  email:string,
  phone: string,
  password?:string,
  companies?:number[]
}


interface updateEmployee {
  id:number,
  name:string,
  email:string,
  password?:string,
  companies?:number[]
}
@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  constructor(private http: HttpClientService) {}

  public createEmployee(body: CreateEmployee) {
    return this.http.post(body, "employees");
  }

  public getAllEmployees(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("employees", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public deleteEmployee(employeeId: number) {
    return this.http.delete({}, `employees/${employeeId}`);
  }
  public getEmployee(employeeId: number) {
    return this.http.get(`employees/${employeeId}`);
  }
  public updateEmployee(employeeId:number, body: updateEmployee) {
    return this.http.put(body, `employees/${employeeId}`);
  }
}
import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class RequestsService {
  constructor(private http: HttpClientService) {}

  public CreateRequest(body: {
    type: string;
    interested_type: string;
    tag: string;
    request_interest: string;
    budget: number;
    down_payment: number;
    delivery_date: Date;
    project_id: number;
    lead_id: number;
    area_id: number;
    unit_id: number;
    company_id: number;
    notes?: string;
  }) {
    return this.http.postFormData(body, "request/create");
  }

  // public getAllRequests(
  //   isPaginate: boolean = false,
  //   page?: number,
  //   search?: string
  // ) {
  //   if (!isPaginate) {
  //     page = null;
  //   }
  //   return this.http.get("request/get-all", {
  //     is_paginate: `${+isPaginate}`,
  //     page,
  //     search,
  //   });
  // }

  public getByLead(leadId: number, isPaginate: boolean = false, page?: number) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("request/get-by-lead", {
      is_paginate: JSON.stringify(+isPaginate),
      page,
      lead_id: leadId,
    });
  }

  public getRequest(requestId: number) {
    return this.http.get("request/get", {
      request_id: requestId,
    });
  }

  public updateUnit(body: {
    type: string;
    interested_type: string;
    tag: string;
    request_interest: string;
    budget: number;
    down_payment: number;
    delivery_date: Date;
    project_id: number;
    lead_id: number;
    area_id: number;
    unit_id: number;
    company_id: number;
    request_id: number;
  }) {
    return this.http.postFormData(body, "request/update");
  }

  public deleteRequest(requestId: number) {
    return this.http.post({ request_id: requestId }, "request/delete");
  }
}

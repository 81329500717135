import {
  Component,
  ViewChild,
  TemplateRef,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
    
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calender',

  styleUrls:['./calender.component.scss'],
  templateUrl: './calender.component.html',
})
export class CalenderComponent implements OnInit,OnDestroy{
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  @Input()changeDate:Subject<Date>;
  @Input()externalEvents:BehaviorSubject<CalendarEvent[]>
  viewDate: Date = new Date();


  actions: CalendarEventAction[] = [
    // {
    //   label: '<i class="fas fa-fw fa-pencil-alt"></i>',
    //   a11yLabel: 'Edit',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Edited', event);
    //   },
    // },
    // {
    //   label: '<i class="fas fa-fw fa-trash-alt"></i>',
    //   a11yLabel: 'Delete',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.events = this.events.filter((iEvent) => iEvent !== event);
    //     this.handleEvent('Deleted', event);
    //   },
    // },
    // {
    //   label: '<i class="fa-solid fa-info"></i>',
    //   a11yLabel: 'show more details',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Deleted');
    //   },
    // },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [
    // {
    //   start: subDays(startOfDay(this.viewDate), 1),
    //   end: addDays(this.viewDate, 1),
    //   title: 'A 3 day event',
    //   color: { ...colors.red },
    //   actions: this.actions,
    //   allDay: true,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
    // {
    //   start: startOfDay(this.viewDate),
    //   title: 'An event with no end date',
    //   color: { ...colors.yellow },
    //   actions: this.actions,
    // },
    // {
    //   start: subDays(endOfMonth(this.viewDate), 3),
    //   end: addDays(endOfMonth(this.viewDate), 3),
    //   title: 'A long event that spans 2 months',
    //   color: { ...colors.blue },
    //   allDay: true,
    // },
  ];

  activeDayIsOpen: boolean = false;
  subscriptions:Subscription[]=[];

  constructor(private modal: NgxSmartModalService) {}



  ngOnInit(): void {

    const dateSubscription= this.changeDate.subscribe((res)=>{
      if(res){
        console.log(res)
        this.viewDate=res;
      }
    })
    const externalEvents=  this.externalEvents.subscribe((res)=>{
      console.log(res)
      this.events=res;
    })
    this.subscriptions.push(dateSubscription,externalEvents)
    // subscribe to events 

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
   
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        console.log("clicked")
        
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(modalName:string,event?: CalendarEvent): void {
  //   // this.modalData = { event, action };
  // const _modalName=this.modalsNames.find(ele=>ele===modalName);
  // console.log(_modalName)
  // if( _modalName){
  //   this.modal.getModal( _modalName).open();
  // }
  
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  ngOnDestroy(): void {
      this.subscriptions.forEach((ele)=>{
          ele.unsubscribe();
      })
  }
}

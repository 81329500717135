import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class CampignsService {
  constructor(private http: HttpClientService) {}

  // public getAllCampigns(
  //   accessToken: string,
  //   appId: string
  // ) {
  //   return this.http.post( {
  //     access_token: accessToken,
  //     app_id: appId
  //   },
  //   "myCampaigns"
  //   );
  // }

  public getAllCampigns(accessToken: string, addAccId: string) {
    return this.http.get("myCampaigns", {
      access_token: accessToken,
      ad_account: addAccId,
    });
  }

  public createWhatAppCampign(body) {
    return this.http.postFormData(body, "whatsapp-campaigns");
  }
  public getAllWhatAppCampigns(page: number = 1,isPaginate: boolean = false) {
    return this.http.get("whatsapp-campaigns", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class DeveloperService {
  constructor(private http: HttpClientService) {}

  public createDeveloper(body: {
    name_en: number;
    name_ar: number;
    image: string;
  }) {
    return this.http.postFormData(body, "developer/create");
  }

  public getAllDevelopers(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("developer/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getDeveloper(developerId: number, isLocalization: boolean = false) {
    return this.http.get("developer/get", {
      developer_id: developerId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateDeveloper(body: { developer_id: number; name_en: string }) {
    return this.http.postFormData(body, "developer/update");
  }

  public deleteDeveloper(developerId: number) {
    return this.http.post({ developer_id: developerId }, "developer/delete");
  }

  public getDeveloperFilter(body: {
    project_id: number;
    area_id: number;
    unit_type_id: number;
  }) {
    return this.http.get("developer/filter", body);
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class UnitsService {
  constructor(private http: HttpClientService) {}

  public createUnit(body:any) {
  
    return this.http.postFormDataArrayFilesWithOut(body, "unit/create");
  }

  public getAllUnits(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("unit/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getUnit(unitId: number, isLocalization: boolean = false) {
    return this.http.get("unit/get", {
      unit_id: unitId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateUnit(body:any) {
    return this.http.postFormDataArrayFilesWithOut(body,"unit/update");
  }

  public deleteUnit(unitId: number) {
    return this.http.post({ unit_id: unitId }, "unit/delete");
  }
}

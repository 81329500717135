import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface CreateCompany {
  company_name: string;
  logo: File;
  have_spreadsheet:number,
  user: {
    username: string;
    email: string;
    password: string;
  };
}

interface UpdateCompany {
  company_id: number;
  user_id: number;
  company_name: string;
  logo?: File;
  user: {
    username: string;
    email: string;
    password: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  constructor(private http: HttpClientService) {}

  public createCompany(body: CreateCompany) {
    return this.http.postFormData(body, "company/create");
  }

  public getAllCompanies(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("company/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getCompany(companyId: number) {
    return this.http.get("company/get", { company_id: companyId });
  }

  public updateCompany(body: UpdateCompany) {
    return this.http.postFormData(body, "company/update");
  }

  public deleteCompany(companyId: number) {
    return this.http.post({ company_id: companyId }, "company/delete");
  }
}

<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-6 text-center mb-4">
      <h1>Privacy policy</h1>
    </div>
  </div>
  <div class="row">
    <h3>What does this privacy policy cover?</h3>
    <p>
      This Privacy Policy covers our treatment of personally identifiable
      information ("Personal Information") that we gather when you are accessing
      or using our Website or Services. This policy does not apply to the
      practices of companies that we do not own or control, or to individuals
      that we do not employ or manage. We do not knowingly collect or solicit
      personal information from anyone under the age of 13 or knowingly allow
      such persons to register for the Services (as that term is defined in our
      Terms of Use). No one under age 13 may provide any personal information to
      us or on the Services.
    </p>

    <h3>What information does Leads Crm collect?</h3>
    <p>
      We gather various types of Personal Information from our users, as
      explained more fully below. We may use this Personal Information to
      personalize and improve the Services, to allow our users to set up a user
      account and profile, to contact users, to fulfill your requests for the
      Services, to analyze how users utilize the Website, and as otherwise set
      forth in this Privacy Policy. We may share certain types of Personal
      Information with third parties (described in this Section and in Section C
      below). We collect the following types of information:
    </p>

    <h3>A. Information You Provide to Us:</h3>

    <p>
      Your Personal Information: We receive and store any information you
      knowingly provide to us. For example, we collect Personal Information such
      as your name, email address, and browser information. You can choose not
      to provide us with certain information such as your credit card
      information, but then you may not be able to register with us or to take
      advantage of some of our features. We may anonymize your Personal
      Information so that you cannot be individually identified, and provide
      that information to our partners. Your Customers' Personal Information: In
      order to provide you with the Services, we also receive and store any
      information you choose to provide us with respect to your customers (“End
      User Customers"). The End User Customer information (“End User Customer
      Information") we receive and store typically includes names and email
      addresses of your customers, but will consist of any information you
      choose to provide us with. Upon sign up, if you choose to ask a friend to
      install Leads Crm for you, we will ask for your friend's email address to
      notify him of such a request. Leads Crm Inc. stores this information for
      the sole purpose of sending this one-time email notification. If you
      choose to use our referral service to tell a friend about our site, we
      will ask you for your friend's name and email address. We will
      automatically send your friend a one-time email inviting him or her to
      visit the site. Leads Crm Inc. stores this information for the sole
      purpose of sending this one-time email, and tracking the success of our
      referral program. Your friend may contact us at consultant@easychat.co to
      request that we remove this information from our database.
    </p>

    <h3>B. Information Collected Automatically:</h3>
    <p>
      Whenever you interact with our Website, we automatically receive and
      record information on our server logs from your browser including your IP
      address, “cookie" information, and the page you requested. “Cookies" are
      identifiers we transfer to your computer or mobile device that allow us to
      recognize your browser or mobile device and tell us how and when pages in
      our Website are visited by you. You may be able to change the preferences
      on your browser or mobile device to prevent or limit your computer or
      device's acceptance of cookies, but this may prevent you from taking
      advantage of some of our Website's features. If you click on a link to a
      third party website, such third party may also transmit cookies to you.
      When you visit the Website, whether as an Leads Crm customer or a
      non-registered user just browsing, our servers automatically record
      information that your browser sends whenever you visit a website (“Log
      Data"). For example, Log Data may include information such as your
      computer's IP address, browser type or the webpage you were visiting
      before you came to our Website, pages of our Website that you visit, the
      time spent on those pages, information you search for on our Website,
      access times and dates, and other statistics. We use this information to
      monitor and analyze use of the Website and the Services and for the
      Website's technical administration, to increase our Website's
      functionality and user-friendliness, and to better tailor it to our
      visitors' needs. Additionally, as part of our provision of the Services to
      you, if you install certain software code provided by us on your website,
      we will also track and collect End User Customer usage information on your
      website. We also use third parties to collect publicly available
      information relating to your End User Customers. For more information on
      how we collect and compile End User Customer Information, please see
      Section C below. Technologies such as cookies, beacons, scripts and tags
      are used by Leads Crm and our third party partners. These technologies are
      used in analyzing trends, administering the website, tracking users’
      movements around the site, and gathering demographic information about our
      user base as a whole. We may receive reports based on the use of these
      technologies by these companies on an individual and aggregated basis.
      Various browsers may offer their own management tools for removing these
      types of tracking technologies. We use Local Shared Objects, such as Flash
      cookies, and Local Storage, such as HTML5, to store content information
      and preferences. Various browsers may offer their own management tools for
      removing HTML5. Third parties with whom we partner to provide certain
      features on our website or to display advertising based upon your web
      browsing activity also use Flash cookies to collect and store information.
      You can manage Flash cookies here. Our website includes Social Media
      Features, such as the Facebook Like button, and Widgets, such as the Share
      this button or interactive mini-programs that run on our website. These
      Features may collect your Internet protocol address, which page you are
      visiting on our website, and may set a cookie to enable the Feature to
      function properly. Social Media Features and Widgets are either hosted by
      a third party or hosted directly on our website. Your interactions with
      these Features are governed by the privacy statement of the company
      providing it.
    </p>

    <h3>C. E-mail and Other</h3>

    <p>
      Communications: We may contact you, by email or other means; for example,
      we may communicate with you about your use of the Website or Services. If
      you do not want to receive email or other mail from us, please indicate
      your preference by adjusting the settings on your account screen in the
      Website. Please note that if you do not want to receive legal notices from
      us, those legal notices will still govern your use of the Website, and you
      are responsible for reviewing such legal notices for changes. If you wish
      to subscribe to our newsletter(s), we will use your name and email address
      to send the newsletter to you. Out of respect for your privacy, you may
      choose to stop receiving our newsletter or marketing emails by following
      the unsubscribe instructions included in these emails, accessing the email
      preferences in your account settings page or you can contact us at
      contact@leadscrm.net
    </p>

    <h3>Where do we store your personalinformation?</h3>

    <p>
      If you are located outside the United States, the information that we
      collect from you may be transferred to, and stored and processed at, a
      destination in the United States. By submitting information, you agree to
      this transfer, storing or processing. We will take all steps reasonably
      necessary to ensure that your information is treated securely and in
      accordance with this Privacy Policy.
    </p>

    <h3>Will Leads Crm share any of the personal information it receives?</h3>

    <p>
      We neither rent nor sell your Personal Information or your End User
      Customers' Personal Information in personally identifiable form to anyone.
      We share such Personal Information in personally identifiable form with
      third parties as described below.
    </p>

    <h3>A. Trusted Third Parties:</h3>
    <p>
      We employ other companies and people to perform tasks on our behalf and
      need to share your information with them to provide the Services to you.
      Unless we tell you differently, such third parties do not have any right
      to use the Personal Information we share with them beyond what is
      necessary to assist us. This includes third party companies and
      individuals employed by us to facilitate our Services, including the
      provision of maintenance services, database management, Web analytics and
      general improvement of the Services. In particular, as part of our
      provision of the Services to you, we provide a limited amount of your End
      User Customer Information to Full Contact, Inc. (“Full Contact") so that
      Full Contact can collect and compile publicly available contact and social
      information related to your End User Customers, such as email addresses,
      gender, company, job titles, photos, website URLs, social network handles
      and physical addresses. You may opt-out of having such information sent to
      Full Contact by adjusting the settings on your app screen in the Website.
    </p>

    <h3>B. Business Transfers:</h3>
    <p>
      We may choose to buy or sell assets. In these types of transactions,
      customer information is typically one of the business assets that is
      transferred. Also, if we (or substantially all of our assets) are
      acquired, or if we go out of business, enter bankruptcy, or go through
      some other change of control, Personal Information would be one of the
      assets transferred to or acquired by a third party. You will be notified
      via email and/or a prominent notice on our Web site of any change in
      ownership or uses of your personal information, as well as any choices you
      may have regarding your personal information.
    </p>

    <h3>C. Protection of Leads Crm and Others:</h3>
    <p>
      We reserve the right to access, read, preserve, and disclose any
      information that we reasonably believe is necessary to comply with law or
      a court order; enforce or apply our conditions of use and other
      agreements; or protect the rights, property, or safety of Leads Crm our
      employees, our users, or others. This includes exchanging information with
      other companies and organizations for fraud protection and credit risk
      reduction. We also may be required to disclose an individual’s Personal
      Information in response to a lawful request by public authorities,
      including to meet national security or law enforcement requirements.
    </p>

    <h3>D. With Your Consent:</h3>
    <p>
      Except as set forth above, you will be notified when your Personal
      Information may be shared with third parties, and will be able to prevent
      the sharing of this information.
    </p>
    <h3>Is the personal information secure?</h3>

    <p>
      Your account is protected by a password for your privacy and security. You
      must prevent unauthorized access to your account and Personal Information
      by selecting and protecting your password appropriately and limiting
      access to your computer or device and browser by signing off after you
      have finished accessing your account. We endeavor to protect the privacy
      of your account and other Personal Information we hold in our records, but
      we cannot guarantee complete security. The transmission of information via
      the Internet is not completely secure. Unauthorized entry or use, hardware
      or software failure, and other factors, may compromise the security of
      user information at any time. When you enter your information on our site
      we encrypt the transmission of that information using transport layer
      security (TLS). The Website may contain links to other sites. We are not
      responsible for the privacy policies and/or practices on other sites. When
      following a link to another site you should read that site's privacy
      policy.
    </p>
    <h3>What personal information can I access?</h3>
    <p>
      Through your account settings, you may access, and, in some cases, edit or
      delete the following information you've provided to us: name and password
      email address profile photo company name position in company location
      credit card information
    </p>

    <h3>Testimonials</h3>
    <p>
      We post customer testimonials/comments/reviews on our web site which may
      contain personally identifiable information. We do obtain the customer's
      consent via email prior to posting the testimonial to post their name
      along with their testimonial. To request removal of your personal
      information from Testimonials or comments please contact us at
      contact@leadscrm.net
    </p>

    <h3>What choices do I have?</h3>
    <p>
      You can always opt not to disclose information to us, but keep in mind
      some information may be needed to register with us or to take advantage of
      some of our special features. You may be able to add, update, or delete
      information as explained in Section C above. When you update information,
      however, we may maintain a copy of the unrevised information in our
      records. We will retain your information for as long as your account is
      active or as needed to provide you services. You may request deletion of
      your account by contacting us at contact@leadscrm.net Please note that
      some information may remain in our private records after your deletion of
      such information from your account. We will retain and use your
      information as necessary to comply with our legal obligations, resolve
      disputes, and enforce our agreements but barring legal requirements, we
      will delete your information within 90 days. We may use any aggregated
      data derived from or incorporating your Personal Information after you
      update or delete it, but not in a manner that would identify you
      personally. Our Web site offers publicly accessible blogs or community
      forums. You should be aware that any information you provide in these
      areas may be read, collected, and used by others who access them. To
      request removal of your Personal Information from our blog or community
      forum, contact us at contact@leadscrm.net In some cases, we may not be
      able to remove your personal information, in which case we will let you
      know if we are unable to do so and why.
    </p>

    <h3>Changes to this privacy policy</h3>
    <p>
      We may amend this Privacy Policy from time to time. Use of information we
      collect now is subject to the Privacy Policy in effect at the time such
      information is used. If we make material changes or changes in the way we
      use Personal Information, we will notify you by posting an announcement on
      our Website or sending you an email prior to the change becoming
      effective. You are bound by any changes to the Privacy Policy when you use
      the Website after such changes have been first posted. Please note that if
      your complaint is not resolved through these channels, under limited
      circumstances, a binding arbitration option may be available before a
      Privacy Shield Panel.
    </p>

    <h3>Types of information covered by Leads Crm Inc’s Privacy Policy</h3>

    <p>
      Human Resources Data refers to personal data about employees, past or
      present, collected in the context of the employment relationship.
      Information other than Human Resources data includes the following:
      customer, client, visitor, and clinical trial data. Purposes for which
      Leads Crm Inc. collects and processes personal data in reliance on the
      Privacy Shield Please refer to Section “What information does Leads Crm
      collect?” of this Privacy Policy. In particular, and regarding the
      disclosure of your Personal Data, please refer to Section “Will Leads Crm
      share any of the personal information it receives?” of this Privacy
      Policy.
    </p>
  </div>
</div>

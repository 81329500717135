import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class ActionService {
  constructor(private http: HttpClientService) {}

  public createAction(body: {
    date: Date;
    time: number;
    notes: string;
    request_id: number;
    action_status_id: number;
    action_type: string;
  }) {
    return this.http.postFormData(body, "action/create");
  }

  public getAction(actionId: number) {
    return this.http.get("action/get", {
      action_id: actionId,
    });
  }

  public updateAction(body: {
    date: Date;
    time: number;
    notes: string;
    request_id: number;
    action_status_id: number;
    action_type: string;
    action_id: number;
  }) {
    return this.http.postFormData(body, "action/update");
  }

  public deleteAction(actionId: number) {
    return this.http.post({ action_status_id: actionId }, "action/delete");
  }
}

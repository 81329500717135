import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class UnitTypeService {
  constructor(private http: HttpClientService) {}

  public createUnit(body: { name_en: number; name_ar: number }) {
    return this.http.postFormData(body, "unit-type/create");
  }

  public getAllUnits(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("unit-type/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getUnit(unittypeId: number, isLocalization: boolean = false) {
    return this.http.get("unit-type/get", {
      unit_type_id: unittypeId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateUnit(body: { unit_type_id: number; name_en: string }) {
    return this.http.postFormData(body, "unit-type/update");
  }

  public deleteUnit(unittypeId: number) {
    return this.http.post({ unit_type_id: unittypeId }, "unit-type/delete");
  }
}

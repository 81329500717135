import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClientService) {}

  public getAllProjects(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("developer/project/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public createProject(body: {
    name_en: number;
    name_ar: number;
    area_id: number;
    developer_id: number;
    unit_type_id: number;
  }) {
    return this.http.postFormData(body, "developer/project/create");
  }

  public updateProject(body: {
    project_id: number;
    name_en: string;
    name_ar: string;
  }) {
    return this.http.postFormData(body, "developer/project/update");
  }

  public deleteProject(projectId: number) {
    return this.http.post(
      { project_id: projectId },
      "developer/project/delete"
    );
  }
  public getbyProject(projectId: number, isLocalization: boolean = false) {
    return this.http.get("developer/project/get", {
      id: projectId,
      is_localization: `${+isLocalization}`,
    });
  }
  public getbyDeveloper(developerId: number) {
    return this.http.get("developer/project/get-by-developer", {
      developer_id: developerId,
    });
  }
  public getbyArea(areaId: number) {
    return this.http.get("developer/project/get-by-area", { area_id: areaId });
  }
  public getbyUnit(unitId: number) {
    return this.http.get("developer/project/get-by-unit-type", {
      unit_type_id: unitId,
    });
  }

  public getProjectFilter(body: {
    developer_id: number;
    area_id: number;
    unit_type_id: number;
  }) {
    return this.http.get("developer/project/filter", body);
  }
}

import { HttpClientService } from "./../../http-confing/http/http-client.service";
import { Injectable } from "@angular/core";

interface googleSheet {
  sheet_id: string;
}

@Injectable({
  providedIn: "root",
})
export class GoogleSheetsService {
  constructor(private http: HttpClientService) {}

  public getSheetId() {
    return this.http.get(`company/spreadsheet`);
  }
  public updateSheetId(body: googleSheet) {
    return this.http.put(body, `company/spreadsheet`);
  }

  public getAllSheets(spreadsheetId: number) {
    return this.http.get(`google-sheets/${spreadsheetId}/sheets`);
  }

  public getFormSheets(formId: number, platform?: string) {
    let platformEndpoint;
    if (platform == "tiktok") {
      platformEndpoint = `tiktok/forms/${formId}`;
      // return this.http.get( `forms/${formId}`);
    } else {
      platformEndpoint = `forms/${formId}`;
    }
    // console.log(platformEndpoint);
    
    return this.http.get(platformEndpoint);
  }

  public assignSheetToForm(formId: number, sheetId: number, platform?: string) {
    let platformEndpoint;
    if (platform == "tiktok") {
      platformEndpoint = `tiktok/forms/${formId}`;
      // return this.http.get( `forms/${formId}`);
    } else {
      platformEndpoint = `forms/${formId}`;
    }
    return this.http.put({ spreadsheet: sheetId }, platformEndpoint);
  }

  public updateFormSheet(formId: number, body, platform?: string) {
    let platformEndpoint;
    if (platform == "tiktok") {
      platformEndpoint = `tiktok/forms/${formId}`;
      // return this.http.get( `forms/${formId}`);
    } else {
      platformEndpoint = `forms/${formId}`;
    }
    return this.http.put(body, platformEndpoint);
  }

  public syncToGoogleSheets(formId: number, platform?: string) {
    let platformEndpoint;
    if (platform == "tiktok") {
      platformEndpoint = `tiktok/forms/${formId}/sync`;
      // return this.http.get( `forms/${formId}`);
    } else {
      platformEndpoint = `forms/${formId}/sync`;
    }
    return this.http.post({}, platformEndpoint);
  }

  // tiktok

  // tiktok
}

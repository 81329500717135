import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
@Injectable({
  providedIn: "root",
})
export class FacilityService {
  constructor(private http: HttpClient,private httpRequest:HttpClientService) {}
  host=environment.host;
  // TODO company id sent when user is admin

  public createFacility(body: { name_en: string; name_ar: string }) {

    return this.http.post(this.host+'/api/facility/create',body);
  }

  public getFacility(facilityId:number){
    return this.http.get<{data:any}>(this.host+'/api/facility/get?facility_id='+facilityId);
  }
  public getAllFacilities(

    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }

    // TODO send company id if user is admin
    return this.httpRequest.get("facility/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
    // return this.http.get<{data:any}>(this.host+'/api/facility/get-all',{});
  }


  // TODO send company id if user is admin

  public updateFacility(body: {
   facility_id: number;
    name_en: string;
    name_ar?: number;
  })
  {
   
    return this.http.post(this.host+'/api/facility/update',body);
  }

  public deleteFacility(facilityId: number) {
    
    return this.http.post(this.host+'/api/facility/delete',{'facility_id':facilityId});
  }
}

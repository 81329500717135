import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class ActionStatusService {
  constructor(private http: HttpClientService) {}

  public createActionStatus(body: { name_en: number; name_ar: number }) {
    return this.http.postFormData(body, "action-status/create");
  }

  public getAllActionStatus(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("action-status/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getActionStatus(
    actionstatusId: number,
    isLocalization: boolean = false
  ) {
    return this.http.get("action-status/get", {
      action_status_id: actionstatusId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateActionStatus(body: {
    action_status_id: number;
    name_en: string;
    name_ar: number;
  }) {
    return this.http.postFormData(body, "action-status/update");
  }

  public deleteActionStatus(actionstatusId: number) {
    return this.http.post(
      { action_status_id: actionstatusId },
      "action-status/delete"
    );
  }
}

<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"
        ><i class="icon feather icon-bell"></i>
        <sup>
          <span
            *ngIf="unreadNotifications$ | async as unreadNotifications"
            class="notification--alert"
            >{{ unreadNotifications }}</span
          >
        </sup>
      </a>
      <div
        ngbDropdownMenu
        class="dropdown-menu dropdown-menu-right notification"
      >
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <a
              href="javascript:"
              class="m-r-10"
              (click)="markAllNotificationsAsRead()"
              >mark as read</a
            >
          </div>
        </div>
        <perfect-scrollbar [style.max-height]="'300px'">
          <ul class="noti-body">
            <li class="n-title">
              <p class="m-b-0">NEW</p>
            </li>
            <li *ngIf="!notifications.length" class="notification">
              <p class="text-secondary text-center">
                You have no new notifications
              </p>
            </li>
            <ng-container *ngIf="notifications">
              <li
                class="notification"
                *ngFor="let notification of notifications"
              >
                <div class="media">
                  <img
                    class="img-radius"
                    src="assets/images/user/avatar-1.jpg"
                    alt="Generic placeholder image"
                  />
                  <a
                    href="javascript"
                    [routerLink]="[
                      '/leads/' +
                        notification?.data?.key +
                        '/' +
                        notification?.data?.id
                    ]"
                  >
                    <div class="media-body">
                      <span
                        [ngClass]="{ unread: !notification?.read_at }"
                        (click)="markNotificationAsRead(notification?.id)"
                        class="notification"
                      >
                        {{ notification?.data?.msg }}
                      </span>
                      <span class="n-time text-muted mt-2"
                        ><i class="icon feather icon-clock m-r-10"></i
                        >{{
                          getNotificationDate(notification?.created_at)
                        }}</span
                      >
                    </div>
                  </a>
                </div>
              </li>
            </ng-container>
            <li class="n-title">
              <p class="m-b-0">EARLIER</p>
            </li>
            <ng-container *ngIf="readNotifications.length">
              <li
                *ngFor="let readNotification of readNotifications"
                class="notification"
              >
                <div class="media">
                  <img
                    class="img-radius"
                    src="assets/images/user/avatar-2.jpg"
                    alt="Generic placeholder image"
                  />
                  <a
                    href="javascript"
                    [routerLink]="[
                      '/leads/' +
                        readNotification?.data?.key +
                        '/' +
                        readNotification?.data?.id
                    ]"
                  >
                    <div class="media-body">
                      <span
                        [ngClass]="{ unread: !readNotification?.read_at }"
                        (click)="markNotificationAsRead(readNotification?.id)"
                        class="notification"
                      >
                        {{ readNotification?.data?.msg }}
                      </span>
                      <span class="n-time text-muted mt-2"
                        ><i class="icon feather icon-clock m-r-10"></i
                        >{{
                          getNotificationDate(readNotification?.created_at)
                        }}</span
                      >
                    </div>
                  </a>
                </div>
              </li>
            </ng-container>
            <!-- <li class="notification">
              <div class="media">
                <img
                  class="img-radius"
                  src="assets/images/user/avatar-1.jpg"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p>
                    <strong>Sara Soudein</strong
                    ><span class="n-time text-muted"
                      ><i class="icon feather icon-clock m-r-10"></i>12
                      min</span
                    >
                  </p>
                  <p>currently login</p>
                </div>
              </div>
            </li> -->
            <!-- <li class="notification">
              <div class="media">
                <img
                  class="img-radius"
                  src="assets/images/user/avatar-2.jpg"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <p>
                    <strong>Joseph William</strong
                    ><span class="n-time text-muted"
                      ><i class="icon feather icon-clock m-r-10"></i>30
                      min</span
                    >
                  </p>
                  <p>Prchace New Theme and make payment</p>
                </div>
              </div>
            </li> -->
          </ul>
        </perfect-scrollbar>
        <div class="noti-footer">
          <a href="javascript:" (click)="loadMoreNotifications()">show more</a>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu
      >
        <div class="pro-head">
          <img
            src="assets/images/user/avatar-1.jpg"
            class="img-radius"
            alt="User-Profile-Image"
          />
          <span>{{ user.username }}</span>
          <a href="javascript:" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li>
            <a
              href="javascript:"
              [routerLink]="['/profile']"
              class="dropdown-item"
              ><i class="feather icon-user"></i> Profile</a
            >
          </li>
          <li *ngIf="user.role_id == 2">
            <a
              href="javascript:"
              [routerLink]="['/profile/sms-config']"              
              class="dropdown-item"
              ><i class="feather icon-user"></i> SMS Configuration</a
            >
          </li>
          <li *ngIf="user.role_id == 1">
            <a
              href="javascript:"
              [routerLink]="['/profile/notifications']"              
              class="dropdown-item"
              ><i class="feather icon-bell"></i> Manage Notifications</a
            >
          </li>
          <li>
            <a href="javascript:" (click)="logout()" class="dropdown-item"
              ><i class="feather icon-log-out m-r-5"></i>Logout</a
            >
          </li>
          <!-- <li>
            <a href="javascript:" class="dropdown-item"
              ><i class="feather icon-mail"></i> My Messages</a
            >
          </li> -->
          <!-- <li>
            <a href="javascript:" class="dropdown-item"
              ><i class="feather icon-lock"></i> Lock Screen</a
            >
          </li> -->
        </ul>
      </div>
    </div>
  </li>
</ul>

import { Injectable } from '@angular/core';
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface CreateManager {
  name:string,
  email:string,
  password:string,
  agentsId:number[]
}


interface UpdateManager {
  id:number,
  name:string,
  email:string,
  password?:string,
  agentsId:number[]
}
@Injectable({
  providedIn: 'root'
})
export class ManagersService {
  constructor(private http: HttpClientService) {}

  public createManager(body: CreateManager) {
    return this.http.post(body, "manager-create");
  }

  public getAllManagers(
    companyId: number,
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("manager-getAll", {
      company_id: companyId,
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getAllAgents(
    companyId: number,
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("agent/get-all", {
      company_id: companyId,
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }
  public deleteManager(managerId: number) {
    return this.http.post({ id: managerId }, "manager-delete");
  }
  public getManager(managerId: number) {
    return this.http.get("manager-get", { id: managerId });
  }

  public getAgent(agentId: number) {
    return this.http.get("agent/get", { agent_id: agentId });
  }

  public updateManager(body: UpdateManager) {
    return this.http.post(body, "manager-update");
  }
}

import { Component, Input, NgZone, OnInit } from "@angular/core";
import { NavigationItem } from "../../navigation";
import { Location } from "@angular/common";
import { NextConfig } from "../../../../../../app-config";
import { FacadeService } from "src/app/services/facade.service";

@Component({
  selector: "app-nav-group",
  templateUrl: "./nav-group.component.html",
  styleUrls: ["./nav-group.component.scss"],
})
export class NavGroupComponent implements OnInit {
  @Input() item: NavigationItem;
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public nextConfig: any;

  role: string;

  constructor(
    private zone: NgZone,
    private location: Location,
    private facade: FacadeService
  ) {
    this.nextConfig = NextConfig.config;
  }

  ngOnInit() {
    // at reload time active and trigger link
    let current_url = this.location.path();
    if (this.location["_baseHref"]) {
      current_url = this.location["_baseHref"] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          parent.classList.add("pcoded-trigger");
        }
        parent.classList.add("active");
      } else if (up_parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          up_parent.classList.add("pcoded-trigger");
        }
        up_parent.classList.add("active");
      } else if (last_parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          last_parent.classList.add("pcoded-trigger");
        }
        last_parent.classList.add("active");
      }
    }
  }

  getPermission(item) {
    if (
      !this.facade.AuthService.isCompany() &&
      !this.facade.AuthService.isAdmin()
    ) {
      const page = this.facade.AuthService.User.pages.filter(
        (page) => page.name === item.id
      );
      if (page.length) {
        return page[0].value;
      }
    } else if (this.facade.AuthService.isAdmin()) {
      return item.role === "admin" || item.role === "both";
    } else if (this.facade.AuthService.isCompany()) {
      return item.role === "company" || item.role === "both";
    }
    return true;
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClientService) {}

  public getLatestAction(body: { company_id: number; sort_by: string }) {
    return this.http.get("reports/last-actions-reports", body);
  }

  public getNumbersOfLead(
    companyId: number,
    sortBy: string,
    isPaginate: boolean = false
  ) {
    return this.http.get("reports/number-of-leads-in-each-source", {
      company_id: companyId,
      sort_by: sortBy,
      is_paginate: `${+isPaginate}`,
    });
  }
  public getUsersLeadNum() {
    return this.http.get("reports/statistics");
  }

  public getStatusesCount() {
    return this.http.get("leads/stat");
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private http: HttpClientService) {}

  public createCategory(body: { name_en: string; name_ar: string }) {
    return this.http.postFormData(body, "category/create");
  }
  public updateCategory(body) {
    return this.http.postFormData(body, "category/update");
  }
  public deleteCategory(categoryId: number) {
    return this.http.post({ category_id: categoryId }, "category/delete");
  }
  public getCategoryData(categoryId: number) {
    return this.http.get("category/get", { category_id: categoryId });
  }
  public getAllCategory(isPaginate: boolean = false, page: number = 1) {
    return this.http.get("category/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
}

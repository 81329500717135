<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>

<ngx-smart-modal
  *ngIf="authNeeded"
  #reLogin
  [autostart]="true"
  [closable]="false"
  [escapable]="false"
  [dismissable]="false"
  identifier="reLogin"
>
  <h1>Security!</h1>
  <p>Please provide your password to continue!</p>
  <form action="javascript:" [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="row mb-4">
      <div class="col-12">
        <h5>
          Email: <span class="paragraph--email">{{ userMail }}</span>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Password:</h5>
      </div>
      <div class="col-11 mx-auto">
        <input
          type="password"
          formControlName="password"
          required
          class="form-control"
          placeholder="Enter your password..."
        />
      </div>
    </div>
    <div class="row mt-4 text-center">
      <div class="col-12">
        <app-field-error
          class="text-center"
          [displayError]="error"
          [errorMsg]="error"
        ></app-field-error>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button (click)="anotherUser()" type="button" class="btn btn-secondary">
          Not You?
        </button>
      </div>
      <div class="col-6 text-right">
        <input
          class="btn btn-primary text-center"
          type="submit"
          value="Proceed"
        />
      </div>
    </div>
  </form>
</ngx-smart-modal>

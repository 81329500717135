import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface CreateAgent {
  user: {
    username: string;
    email: string;
    password: string;
  };
  phones: number[];
  type: "residential" | "commercial" | "both";
  company_id: number;
  teams: number[];
  title_id: number;
}

interface UpdateAgent {
  agent_id: number;
  user_id: number;
  user: {
    username: string;
    email: string;
    password: string;
  };
  phones: number[];
  type: "residential" | "commercial" | "both";
  company_id: number;
  teams: string[];
  title_id: number;
}

@Injectable({
  providedIn: "root",
})
export class AgentsService {
  constructor(private http: HttpClientService) {}

  public createAgent(body: CreateAgent) {
    return this.http.post(body, "agent/create");
  }

  public getAllAgents(
    companyId: number,
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("agent/get-all", {
      company_id: companyId,
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }
  public getAllAttendance(
    from?: Date,
    to?: Date,
  ) {
    // if (!isPaginate) {
    //   page = null;
    // }
    return this.http.get("company/agents-attendance", {
      from,to
    });
  }
  public getAgent(agentId: number) {
    return this.http.get("agent/get", { agent_id: agentId });
  }

  public updateAgent(body: UpdateAgent) {
    return this.http.post(body, "agent/update");
  }

  public deleteAgent(agentId: number) {
    return this.http.post({ agent_id: agentId }, "agent/delete");
  }

  // Whatsapp QR
  public startSession(body: { whatsapp_token: string; id: number }) {
    return this.http.get("start_whatsapp", body);
  }

  public getWhatsappQR(body: { whatsapp_token: string; id: number }) {
    return this.http.get("Qr_whatsapp", body);
  }

  public getWhatsappStatus(body: { whatsapp_token: string; id: number }) {
    return this.http.get("whatsapp_status", body);
  }

  public addWhatsappMessage(
    msg: string,
    body: { whatsapp_token: string; id: number }
  ) {
    let bodyWzMsg = { ...body };
    bodyWzMsg["msg"] = msg;
    return this.http.post(bodyWzMsg, "addToAgent");
  }

  public switchStatus(body: { agent_id: number; active: number }) {
    return this.http.post(
      body,
      `agent/active`
    );
  }

  public getAllAgentsWithLeadsStats() {
    return this.http.get("agent/stats");
  }
}

import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { AuthService } from "../../services/auth/auth.service";
import { ActivatedRoute } from "@angular/router";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private _auth: AuthService, private route: ActivatedRoute) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req.clone({});

    const body = {};
    if (this._auth.asCompany) {
      body["company_id"] = this._auth.asCompany;
    }

    if (this._auth.isCompany()) {
      body["company_id"] = this._auth.isCompany();
    }
    // if token exits will be added to request headers

    if (this._auth.Token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._auth.Token}`,
        },
        setParams: body,
      });
    }
    // if (request.method == "POST") {
    //   request.body['user_id'] = this._facade.Auth.UserId;
    // }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log(err);
            if (err.error.message === "Unauthenticated.") {
              this._auth.expiredTokenSubject.next(true);
            }
          }
          return of(err);
        }
      )
    );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { urlSettings } from "src/app/http-confing/settings/urlSettings";

export const environment = {
  production: false,
  host:'https://backend.leadscrm.net',
  BasemHost:'http://127.0.0.1:8000',
  // For Google Calendar
  GAPI_CLIENT_ID: '445559296795-6e2p2m80ldnjtsgg5r671ap8i0vph9c8.apps.googleusercontent.com',
  GAPI_CLIENT_SECRET: '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',
  GAPI_API_KEY: 'AIzaSyBTyAI2F5h9U2ucu_beB9R5QPXfxpAqVK0',
  firebase: {
    apiKey: "AIzaSyBhkZYPwJUehyEfgFxlbZ6gVQawuNsTLpU",
    authDomain: "findyourhome-7378b.firebaseapp.com",
    databaseURL: "https://findyourhome-7378b.firebaseio.com",
    projectId: "findyourhome-7378b",
    storageBucket: "findyourhome-7378b.appspot.com",
    messagingSenderId: "786737308319",
    appId: "1:786737308319:web:fce9cdb02f6be6343438aa",
    measurementId: "G-6RJBW6YDTC"
    // apiKey: "AIzaSyAnzKv-J8xUxO7Pgmi1_nQdEyQIcx_nnrE",
    // authDomain: "realestate-crm-56d7f.firebaseapp.com",
    // databaseURL: "https://realestate-crm-56d7f.firebaseio.com",
    // projectId: "realestate-crm-56d7f",
    // storageBucket: "realestate-crm-56d7f.appspot.com",
    // messagingSenderId: "15239767275",
    // appId: "1:15239767275:web:5174c4772ac7c55eab4aae",
    // measurementId: "G-BQGMKPWLY1",
  },
};

urlSettings.Settings = {
  // apiHost: "192.168.1.107",
  // apiEndPoint: "api",
  // apiProtocol: "http",
  // apiPort: 5252,

  apiProtocol: "https",
  apiHost: "backend.leadscrm.net",
  apiEndPoint: "api",
  language: "en",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

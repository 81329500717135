import {  NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalenderComponent } from "src/app/demo/calender/calender.component";
import {
  AlertModule,
  BreadcrumbModule,
  CardModule,
  ModalModule,
} from "./components";
import { DataFilterPipe } from "./components/data-table/data-filter.pipe";
import { TodoListRemoveDirective } from "./components/todo/todo-list-remove.directive";
import { TodoCardCompleteDirective } from "./components/todo/todo-card-complete.directive";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";

import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ApexChartComponent } from "./components/chart/apex-chart/apex-chart.component";
import { ApexChartService } from "./components/chart/apex-chart/apex-chart.service";
import { ToastComponent } from "./components/toast/toast.component";
import { ToastService } from "./components/toast/toast.service";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { LightboxModule } from "ngx-lightbox";
import {NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({

  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    LightboxModule,
    NgbDatepickerModule,
  

    FileUploadModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    NgbDatepickerModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,

    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    ApexChartComponent,
    GalleryComponent,
    ToastComponent,
    FileUploadModule,
    CalendarModule,
    CalenderComponent
    
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    ApexChartComponent,
    ToastComponent,
    GalleryComponent,
    CalenderComponent
  ],
  providers: [
    
    {
   
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
   
    ApexChartService,
    ToastService,
  ],

})
export class SharedModule {}
import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class TitlesService {
  constructor(private http: HttpClientService) {}

  // TODO company id sent when user is admin
  public createTitle(body: { name: string; companyId?: number }) {
    return this.http.post(body, "title/create");
  }

  public getAllTitles(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }

    // TODO send company id if user is admin
    return this.http.get("title/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getTitle(titleId: number) {
    return this.http.get("title/get", { title_id: titleId });
  }

  // TODO send company id if user is admin
  public updateTitle(body: {
    title_id: number;
    name: string;
    company_id?: number;
  }) {
    return this.http.post(body, "title/update");
  }

  public deleteTitle(titleId: number) {
    return this.http.post({ title_id: titleId }, "title/delete");
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { FacadeService } from "../facade.service";

interface CreateTeam {
  name: string;
  image: string;

  // Company id is sent if user is Admin
  company_id?: number;
  agents?: number[];
}

interface UpdateTeam {
  name: number;
  image?: string;

  // Company id is sent if user is Admin
  company_id: number;
  team_id: number;
  agents?: number[];
}

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  constructor(private http: HttpClientService) {}

  public createTeam(body: CreateTeam) {
    return this.http.postFormData(body, "team/create");
  }

  public getAllTeams(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }

    // TODO sent company id if user is admin
    return this.http.get("team/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getTeam(teamId: number) {
    return this.http.get("team/get", { team_id: teamId });
  }

  public updateTeam(body: UpdateTeam) {
    return this.http.postFormData(body, "team/update");
  }

  public deleteTeam(teamId: number) {
    return this.http.post({ team_id: teamId }, "team/delete");
  }
}

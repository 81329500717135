import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { FacadeService } from "./services/facade.service";
import { MessagingService } from "./services/notification/notification.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loginForm: FormGroup;
  userMail: string;
  error: string;
  authNeeded: boolean;

  constructor(
    private router: Router,
    private facade: FacadeService,
    private messagingService: MessagingService,
    private modalService: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.facade.AuthService.expiredTokenSubject.subscribe((val) => {
      if (val === true) {
        this.authNeeded = true;
        this.userMail = this.facade.AuthService.User.email;
        this.initForm();
      }
    });

    this.messagingService.requestPermission();
    // this.getPermissions();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private initForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(this.userMail, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl("", [Validators.required]),
    });
  }

  login() {
    this.facade.AuthService.login(this.loginForm.value).subscribe(
      (res) => {
        this.facade.AuthService.User = res.data;
        this.modalService.close("reLogin");
        location.reload();
      },
      (err) => {
        this.error = err.error.data;
      }
    );
  }

  anotherUser() {
    this.facade.AuthService.removeUser();
    this.router.navigate(["/auth/signin"]);
  }

  // getPermissions() {
  //   this.facade.RolesService.listPermissions().subscribe((res) => {
  //     this.validatePermissions(res.data);
  //   });
  // }

  // validatePermissions(data) {
  //   const permissions = this.mapPermissions(data);
  //   this.facade.RolesService.validatePermissions(permissions, true).subscribe(
  //     (res) => {
  //       console.log(res);
  //     }
  //   );
  // }

  // mapPermissions(data) {
  //   const mappedPermissions = [];
  //   Object.keys(data).forEach((key) => {
  //     mappedPermissions.push(...data[key].map((permission) => permission.id));
  //   });
  //   console.log(mappedPermissions);
  //   return mappedPermissions;
  // }
}

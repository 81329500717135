import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private http: HttpClientService) {}

  public getAllNotificationsPaginate(page?: number) {
    return this.http.get("user/notification/get-all", { page });
  }

  public getNotification(notificationId: string) {
    return this.http.get("user/notification/get", { id: notificationId });
  }

  public get markAsRead() {
    return this.http.get("user/notification/mark-as-read");
  }

  public get totalUnread() {
    return this.http.get("user/notification/total-unread-notifications");
  }

  // Notifications (get all, add new, update)
  public getAllNotifications(isPaginate: boolean = false, page?: number) {
    return this.http.get("notifications/get", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }

  public getAllRoles() {
    return this.http.get("notifications/role");
  }

  public createNotification(body: { name: string; type: [string] }) {
    return this.http.post(body, "notifications/add");
  }

  public deleteNotification(notificationId: number) {
    return this.http.delete({}, `notifications/${notificationId}`);
  }

  public updateNotification(
    body: { name: string; type: [string] },
    notificationId: number
  ) {
    return this.http.put(body, `notifications/${notificationId}`);
  }

  //For Not Admin Role
  public updateStatus(body: {
    active: number;
    notificationId: number;
    userId: number;
  }) {
    return this.http.put(
      body,
      `notifications/${body.notificationId}/user/${body.userId}`
    );
  }
}

import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface ILeadSource {
  name: string;
  is_general: boolean;
  company_id?: number;
}

interface IUpdateLeadSource {
  name: string;
  is_general: boolean;
  source_id: number;
  company_id?: number;
}

@Injectable({
  providedIn: "root",
})
export class LeadsSourcesService {
  constructor(private http: HttpClientService) {}

  public creatLeadSource(body: ILeadSource) {
    return this.http.post(body, "source/create");
  }

  public getLeadSource(leadSourceId: number) {
    return this.http.get("source/get", { source_id: leadSourceId });
  }

  public getAllLeadsSources(
    isPaginate: boolean = false,
    search?: string,
    page: number = 1
  ) {
    return this.http.get("source/get-all", {
      is_paginate: `${+isPaginate}`,
      search,
      page,
    });
  }

  public updateLeadSource(body: IUpdateLeadSource) {
    return this.http.post(body, "source/update");
  }

  public deleteLeadSource(leadSourceId: number) {
    return this.http.post({ source_id: leadSourceId });
  }
}

<ng-container *ngIf="!item.hidden">
  <li class="nav-item pcoded-menu-caption">
    <label>{{ item.title }}</label>
  </li>
  <ng-container *ngFor="let item of item.children">
    <ng-container *ngIf="getPermission(item)">
      <app-nav-group *ngIf="item.type == 'group'" [item]="item"></app-nav-group>
      <app-nav-collapse
        *ngIf="item.type == 'collapse'"
        [item]="item"
      ></app-nav-collapse>
      <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
    </ng-container>
  </ng-container>
</ng-container>

import { ChatService } from './chat/chat.service';
import { GoogleSheetsService } from './google-sheets/google-sheets.service';
import { EmployeesService } from './employees/employees.service';
import { ManagersService } from './managers/managers.service';
import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { CompaniesService } from "./companies/companies.service";
import { AgentsService } from "./agents/agents.service";
import { TeamsService } from "./teams/teams.service";
import { TitlesService } from "./titles/titles.service";
import { AreaService } from "./area/area.service";
import { UnitTypeService } from "./unit-type/unit-type.service";
import { DeveloperService } from "./developer/developer.service";
import { ProjectService } from "./project/project.service";
import { RolesService } from "./roles/roles.service";
import { LeadsService } from "./leads/leads.service";
import { LeadsSourcesService } from "./leads-sources/leads-sources.service";
import { UnitsService } from "./units/units.service";
import { RequestsService } from "./requests/requests.service";
import { ActionService } from "./action/action.service";
import { ActionStatusService } from "./action-status/action-status.service";
import { MessagingService } from "./notification/notification.service";
import { NotificationsService } from "./notifications/notifications.service";
import { DashboardService } from "./dashboard/dashboard.service";
import { CategoryService } from "./category/category.service";
import { ProductsService } from "./products/products.service";
import { CampignsService } from "./campigns/campigns.service";
import { GoogleCalendarService } from './calendars/google-calendar.service';
import { FacilityService } from './facilities/facility.service';
@Injectable({
  providedIn: "root",
})
export class FacadeService {
  private _authService: AuthService;
  private _companiesService: CompaniesService;
  private _agentsService: AgentsService;
  private _teamsService: TeamsService;
  private _titlesService: TitlesService;
  private _facilitiesService:FacilityService;
  private _areaService: AreaService;
  private _unitService: UnitsService;
  private _unittypeService: UnitTypeService;
  private _developerService: DeveloperService;
  private _projectService: ProjectService;
  private _rolesService: RolesService;
  private _leadsService: LeadsService;
  private _leadsSourcesService: LeadsSourcesService;
  private _requestService: RequestsService;
  private _actionService: ActionService;
  private _actionstatusService: ActionStatusService;
  private _messageService: MessagingService;
  private _notificationService: NotificationsService;
  private _dashboardService: DashboardService;
  private _categoryService: CategoryService;
  private _productsService: ProductsService;
  private _campignsService: CampignsService;
  private _managersService: ManagersService;
  private _employeesServices: EmployeesService;
  private _googleSheetsService: GoogleSheetsService;
  private _chatService: ChatService;
  private _googleCalendar: GoogleCalendarService;

  constructor(private injector: Injector) { }

  public get AuthService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  public get CompaniesService(): CompaniesService {
    if (!this._companiesService) {
      this._companiesService = this.injector.get(CompaniesService);
    }
    return this._companiesService;
  }
  public get ManagersService(): ManagersService {
    if (!this._managersService) {
      this._managersService = this.injector.get(ManagersService);
    }
    return this._managersService;
  }
  public get EmployeesService(): EmployeesService {
    if (!this._employeesServices) {
      this._employeesServices = this.injector.get(EmployeesService);
    }
    return this._employeesServices;
  }
  public get AgentssService(): AgentsService {
    if (!this._agentsService) {
      this._agentsService = this.injector.get(AgentsService);
    }
    return this._agentsService;
  }

  public get TeamsService(): TeamsService {
    if (!this._teamsService) {
      this._teamsService = this.injector.get(TeamsService);
    }
    return this._teamsService;
  }

  public get TitlesService(): TitlesService {
    if (!this._titlesService) {
      this._titlesService = this.injector.get(TitlesService);
    }
    return this._titlesService;
  }
  public get FacilitiesService():FacilityService{
    if (!this._facilitiesService) {
      this._facilitiesService = this.injector.get(FacilityService);
    }
    return this._facilitiesService;
  }
  public get GoogleSheetsService(): GoogleSheetsService {
    if (!this._googleSheetsService) {
      this._googleSheetsService = this.injector.get(GoogleSheetsService);
    }
    return this._googleSheetsService;
  }
  public get Areaservice(): AreaService {
    if (!this._areaService) {
      this._areaService = this.injector.get(AreaService);
    }
    return this._areaService;
  }
  public get Unitsservice(): UnitsService {
    if (!this._unitService) {
      this._unitService = this.injector.get(UnitsService);
    }
    return this._unitService;
  }
  public get UnitTypeservice(): UnitTypeService {
    if (!this._unittypeService) {
      this._unittypeService = this.injector.get(UnitTypeService);
    }
    return this._unittypeService;
  }
  public get Developerservice(): DeveloperService {
    if (!this._developerService) {
      this._developerService = this.injector.get(DeveloperService);
    }
    return this._developerService;
  }
  public get Projectservice(): ProjectService {
    if (!this._projectService) {
      this._projectService = this.injector.get(ProjectService);
    }
    return this._projectService;
  }
  public get RolesService(): RolesService {
    if (!this._rolesService) {
      this._rolesService = this.injector.get(RolesService);
    }
    return this._rolesService;
  }
  public get LeadsService(): LeadsService {
    if (!this._leadsService) {
      this._leadsService = this.injector.get(LeadsService);
    }
    return this._leadsService;
  }
  public get LeadsSourcesService(): LeadsSourcesService {
    if (!this._leadsSourcesService) {
      this._leadsSourcesService = this.injector.get(LeadsSourcesService);
    }
    return this._leadsSourcesService;
  }
  public get RequestService(): RequestsService {
    if (!this._requestService) {
      this._requestService = this.injector.get(RequestsService);
    }
    return this._requestService;
  }
  public get ActionService(): ActionService {
    if (!this._actionService) {
      this._actionService = this.injector.get(ActionService);
    }
    return this._actionService;
  }
  public get ActionstatusService(): ActionStatusService {
    if (!this._actionstatusService) {
      this._actionstatusService = this.injector.get(ActionStatusService);
    }
    return this._actionstatusService;
  }
  public get messageService(): MessagingService {
    if (!this._actionstatusService) {
      this._messageService = this.injector.get(MessagingService);
    }
    return this._messageService;
  }
  public get notificationService(): NotificationsService {
    if (!this._notificationService) {
      this._notificationService = this.injector.get(NotificationsService);
    }
    return this._notificationService;
  }
  public get dashboardService(): DashboardService {
    if (!this._dashboardService) {
      this._dashboardService = this.injector.get(DashboardService);
    }
    return this._dashboardService;
  }
  public get categoryService(): CategoryService {
    if (!this._categoryService) {
      this._categoryService = this.injector.get(CategoryService);
    }
    return this._categoryService;
  }
  public get productService(): ProductsService {
    if (!this._productsService) {
      this._productsService = this.injector.get(ProductsService);
    }
    return this._productsService;
  }
  public get CampignsService(): CampignsService {
    if (!this._campignsService) {
      this._campignsService = this.injector.get(CampignsService);
    }
    return this._campignsService;
  }

  public get ChatService(): ChatService {
    if (!this._chatService) {
      this._chatService = this.injector.get(ChatService);
    }
    return this._chatService;
  }
  public get GoogleCalendarService(): GoogleCalendarService {
    if (!this._googleCalendar) {
      this._googleCalendar = this.injector.get(GoogleCalendarService);
    }
    return this._googleCalendar;
  }
}
